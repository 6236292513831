import DemographicsTemplate from '@/modules/questionnaire/components/steps/common/identity/demographics/DemographicsTemplate';

export default {
  title: 'Steps/Identity/Demographics',
  component: DemographicsTemplate
};

const createStory = props => () => ({
  components: { DemographicsTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><demographics-template v-bind="$options.storyProps" /></div>'
});

export const Male = createStory({ dateOfBirth: '2005-01-15', gender: 'male' });

export const OtherFemale = createStory({
  dateOfBirth: '2005-01-15',
  gender: 'other',
  genotype: 'female',
  genotypeVisible: true
});
